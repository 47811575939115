<template>
  <div class="body">

    <div id="app">
      <section ref="topHeader" style="top: 0;width: 100%;max-width: 768px;z-index: 1000;" class="top-header"
        v-if="current_page != 'print-game'" v-show="current_page != 'casinogames'">

        <a href="/app/tucheze-v3.apk" class="deposit-link">
          <div class="row download-app d-flex align-items-center justify-content-between">

            <div class="d-flex align-items-right">
              <img style="width: 40px; margin-right: 1px;" src="/img/android.png" />
              <div style="margin-right: 1px;" class="d-flex flex-column">
                <strong>Get App!</strong>
                <strong>Get App Bonus.</strong>
              </div>
            </div>

            <div class="d-flex">
              <div style="margin-right: 10px;" class="d-flex flex-column">
                <strong>Free</strong>
                <strong>Download</strong>
              </div>
              <img style="width: 30px;" src="/img/download.png" />
            </div>   
          </div>
        </a>

        <div class="d-flex m-0 align-items-center header" style="background-color: var(--darkBackground); "
          v-show="current_page != 'deposit' && current_page != 'casinogames'">


          <div class="d-flex align-items-center px-0 pt-1 pb-1 m-0 text-center logo">
            <a class="" @click="showMenu">
              <img style="width: 24px;" src="/img/icon/icons-menu.png" />
            </a>

            <router-link class="" to="/search">
              <img style="width: 24px;" src="/img/icon/icons-search.png" />
            </router-link>
            <router-link to="/">
              <img style="float: left;width: 140px;" src="/img/TuchezeLogo.svg" class="top-header-logo img-fluid"
                alt="Tucheze.com Logo">
            </router-link>
          </div>



          <div class="p-0 login">

            <div v-if="profile" class=" d-flex depo text-center justify-content-around ">
              <!-- <router-link to="/gifts">
                <img src="/img/icon/gift_icon.png" class="wallet-icon"> <br>
                <span class="bet-history text-yellow wallet-text">
                  <strong>Promos </strong>
                </span>
              </router-link> -->
              <router-link to="/my-account">
                <img src="/img/icon/new_wallet.png" class="wallet-icon"> <br>
                <span class="wallet-balance text-yellow wallet-text">
                  <strong>Ksh<span v-text="bal"></span></strong>
                </span>
              </router-link>
            </div>

            <div v-else class="d-flex text-center d-none">
              <router-link to="/login" class="header-login-button small-text mr-1">
                Login
              </router-link>

              <router-link to="/join" class="header-join-button small-text" style="color: #000 !important;">
                Join
              </router-link>
            </div>
          </div>
        </div>

      </section>

      <section style="position: relative;z-index: 500;" class="feature-menu p0 pb-3" v-if="current_page != 'print-game'"
        v-show="current_page != 'deposit' && current_page != 'casinogames'">

        <div class="icons-wrapper top-menu-items d-flex justify-content-between">

          <div style="margin-right: -15px;" class="feature-icon-item-wrapper text-center"
            v-bind:class="isActive('sport')">
            <router-link to="/">
              <div class="icon-container">
                <img src="/img/TopNav/Soccer.svg" class="menu-icon-sports-svg">
              </div>
              <span class="text-blue txt">Football</span>
            </router-link>
          </div>

          <div style="margin-right: -15px;" class="feature-icon-item-wrapper text-center"
            v-bind:class="isActive('home')">
            <router-link to="/crash">
              <div class="icon-container">
                <img style="fill: red;" src="/img/TopNav/Growth.svg" class="menu-icon-svg">
              </div>
              <span class="text-blue txt">Crash</span>
            </router-link>
          </div>

          <!-- <div style="margin-right: -15px;" v-for="(game, index) in aviatorGame" v-bind:key="getKey(index)"
            class="feature-icon-item-wrapper text-center">
            <router-link
              :to="{ name: 'aviator', params: { IsDemo: 0, providerID: game.providerID, gameName: game.gameName, gameID: game.gameID } }">
              <div class="crash-icon-container">
                <img v-bind:src="game.image" style="width: 100%;height: 100%;border-radius: 6px;"
                  class="menu-icon-svg" />
              </div>
              <span class="text-blue txt">{{ game.gameName }}</span>

            </router-link>
          </div>

          <div style="margin-right: -15px;" v-for="(game, index) in homePageGame" v-bind:key="getKey(index)"
            class="feature-icon-item-wrapper text-center">
            <router-link
              :to="{ name: 'casinogames', params: { IsDemo: 0, providerID: game.providerID, gameName: game.gameName, gameID: game.gameID } }">
              <div class="crash-icon-container">
                <img v-bind:src="game.image" style="width: 100%;height: 100%;border-radius: 6px;"
                  class="menu-icon-svg" />
              </div>
              <span class="text-blue txt">{{ game.gameName }}</span>

            </router-link>
          </div> -->

          <div style="margin-left: 3px;" class="feature-icon-item-wrapper text-center "
            v-bind:class="isActive('casino')">
            <router-link to="/casino">
              <div class="icon-container">
                <img src="/img/TopNav/Chip.svg" class="menu-icon-svg">
                <sup><span class="custom-badge">NEW</span></sup>
              </div>
              <span class="text-blue txt">Casino</span>
            </router-link>
          </div>

          <!-- <div :class="['feature-icon-item-wrapper', 'text-center', { 'active': isActive('live') }]">
            <a @click="goTo('live')">
              <img src="/img/TopNav/Live.svg" class="menu-icon-svg"> <br>
              <span class="text-blue txt">Live</span>
            </a>
          </div> -->

          <!-- <div style="margin-left: -14px;" class="feature-icon-item-wrapper text-center"
            v-bind:class="isActive('virtuals')">
            <router-link to="/virtuals">
              <div class="icon-container">
                <img src="/img/TopNav/Trophy.svg" class="menu-icon-svg">
              </div>
              <span class="text-blue txt">Virtuals</span>
            </router-link>
          </div> -->

          <div style="margin-left: -14px;" class="feature-icon-item-wrapper text-center"
            v-bind:class="isActive('gifts')">
            <router-link to="/gifts">
              <div class="icon-container">
                <img src="/img/TopNav/Discount.svg" class="menu-icon-svg">
              </div>
              <span class="text-blue txt">Promos</span>
            </router-link>
          </div>

          <div style="margin-left: -14px;" class="feature-icon-item-wrapper text-center"
            v-bind:class="isActive('mobile-app')">
            <router-link to="/mobile-app">
              <div class="icon-container">
                <img src="/img/TopNav/Smartphone.svg" class="menu-icon-svg"> <sup><span
                    class="custom-badge">NEW</span></sup>
              </div>
              <span class="text-blue txt">Lite App</span>
            </router-link>
          </div>
          <!-- <div class="feature-icon-item-wrapper text-center" v-bind:class="isActive('Basketball')">
            <a @click="setSportMenu(2, 'Basketball')">
              <img src="/menu/basket-ball.png" class="menu-icon-sports-svg"> <br>
              <span class="text-blue txt">Basketball</span>
            </a>
          </div>

          <div class="feature-icon-item-wrapper text-center" v-bind:class="isActive('Basketball')">
            <a @click="setSportMenu(21, 'Cricket')">
              <img src="/menu/Cricket.png" class="menu-icon-sports-svg"> <br>
              <span class="text-light txt">Cricket</span>
            </a>
          </div>

          <div class="feature-icon-item-wrapper text-center" v-bind:class="isActive('Basketball')">
            <a @click="setSportMenu(5, 'Tennis')">
              <img src="/menu/Tennis.png" class="menu-icon-sports-svg"> <br>
              <span class="text-light txt">Tennis</span>
            </a>
          </div>

          <div class="feature-icon-item-wrapper text-center" v-bind:class="isActive('Basketball')">
            <a @click="setSportMenu(4, 'IceHockey')">
              <img src="/menu/IceHockey.png" class="menu-icon-sports-svg"> <br>
              <span class="text-light txt">Ice Hockey</span>
            </a>
          </div>

          <div class="feature-icon-item-wrapper text-center" v-bind:class="isActive('Basketball')">
            <a @click="setSportMenu(16, 'AmericanFootball')">
              <img src="/menu/AmericanFootball.png" class="menu-icon-sports-svg"> <br>
              <span class="text-light txt">American Football</span>
            </a>
          </div>

          <div class="feature-icon-item-wrapper text-center" v-bind:class="isActive('Basketball')">
            <a @click="setSportMenu(10, 'Boxing')">
              <img src="/menu/Boxing.png" class="menu-icon-sports-svg"> <br>
              <span class="text-light txt">Boxing</span>
            </a>
          </div>

          <div class="feature-icon-item-wrapper text-center" v-bind:class="isActive('Basketball')">
            <a @click="setSportMenu(31, 'Badminton')">
              <img src="/menu/Badminton.png" class="menu-icon-sports-svg"> <br>
              <span class="text-light txt">Badminton</span>
            </a>
          </div>

          <div class="feature-icon-item-wrapper text-center" v-bind:class="isActive('Basketball')">
            <a @click="setSportMenu(12, 'Rugby')">
              <img src="/menu/Rugby.png" class="menu-icon-sports-svg"> <br>
              <span class="text-light txt">Rugby</span>
            </a>
          </div>

          <div class="feature-icon-item-wrapper text-center" v-bind:class="isActive('Basketball')">
            <a @click="setSportMenu(23, 'Volleyball')">
              <img src="/menu/Volleyball.png" class="menu-icon-sports-svg"> <br>
              <span class="text-light txt">Volleyball</span>
            </a>
          </div>

          <div class="feature-icon-item-wrapper text-center" v-bind:class="isActive('Basketball')">
            <a @click="setSportMenu(6, 'Handball')">
              <img src="/menu/Handball.png" class="menu-icon-sports-svg"> <br>
              <span class="text-light txt">Handball</span>
            </a>
          </div>

          <div class="feature-icon-item-wrapper text-center" v-bind:class="isActive('Basketball')">
            <a @click="setSportMenu(117, 'MixedMartialArts')">
              <img src="/menu/Mma.png" class="menu-icon-sports-svg"> <br>
              <span class="text-light txt">Mixed Martial Arts</span>
            </a>
          </div>

          <div class="feature-icon-item-wrapper text-center" v-bind:class="isActive('Basketball')">
            <a @click="setSportMenu(22, 'Darts')">
              <img src="/menu/Darts.png" class="menu-icon-sports-svg"> <br>
              <span class="text-light txt">Darts</span>
            </a>
          </div> -->
        </div>

      </section>

      <!--A-Z Menu-->
      <div id="menu-modal" class="faso-menu-modal" style="z-index: 99999;">
        <!-- Modal content -->
        <div class="faso-menu-modal-content">
          <section class="w-100 menu-bg">
            <div class="row m-0 p-0 side-menu-header">
              <div>
                <div class="p-3 px-3">
                  <strong class="text-light faso-menu-close" style="cursor: pointer;">
                    <img style="width: 20px;" src="/img/BottomNav/close.png" alt="Tucheze.com close menu">
                  </strong>
                </div>
              </div>
              <div>
                <!-- <img src="/img/TuchezeLogo.svg" class="top-header-logo2 pt-3" @click="goTo('home')"> -->
                <img style="width: 140px;" src="/img/TuchezeLogo.svg" class="top-header-logo img-fluid"
                  alt="Tucheze.com Logo" @click="goTo('home')">
              </div>
              <div class="text-right">
                <div v-if="profile" class=" d-flex depo text-center justify-content-around ">

                  <a @click="goTo('my-account')">
                    <img src="/img/icon/new_wallet.png" class="wallet-icon"> <br>
                    <span class="wallet-balance text-yellow wallet-text">
                      <strong>Ksh<span v-text="bal"></span></strong>
                    </span>
                  </a>
                </div>

                <div v-else class="d-flex text-center pr-2 pt-1">
                  <a @click="goTo('login')" class="header-join-button small-text"
                    style="color: #000 !important;margin-top: 9px;">
                    Login
                  </a>
                </div>
              </div>
            </div>

            <hr class="m-1">
            <div style="border-bottom: solid 1px #96a9de;" class="d-flex justify-content-left px-3 py-1 flatlink-div">
              <a @click="goTo('sport')">
                <a class="flat-link">
                  <img src="/menu/soccer.png" class="side-icon-svg mr-2">
                  <span class="text-blue">Sports</span>
                </a>
              </a>
            </div>
            <div style="border-bottom: solid 1px #96a9de;" class="d-flex justify-content-left px-3 py-1 flatlink-div">
              <a @click="goTo('live')">
                <a class="flat-link">
                  <img src="/menu/live.png" class="side-icon-svg mr-2">
                  <span class="text-blue">Live</span>
                </a>
              </a>
            </div>

            <div style="border-bottom: solid 1px #96a9de;" class="d-flex justify-content-left px-3 py-1 flatlink-div">
              <a @click="goTo('virtuals')">
                <a class="flat-link">
                  <img src="/img/TopNav/Soccer.svg" class="side-icon-svg mr-2">
                  <span class="text-blue">Virtuals</span>
                </a>
              </a>
            </div>

            <div style="border-bottom: solid 1px #96a9de;" class="d-flex justify-content-left px-3 py-1 flatlink-div">
              <a @click="goTo('casino')">
                <a class="flat-link">
                  <img src="/menu/casino.png" class="side-icon-svg mr-2">
                  <span class="text-blue">Casino</span>
                </a>
              </a>
            </div>

            <div style="border-bottom: solid 1px #96a9de;" class="d-flex justify-content-left px-3 py-1 flatlink-div">
              <a @click="goTo('jackpot')">
                <a class="flat-link">
                  <img src="/menu/jackpot.png" class="side-icon-svg mr-2">
                  <span class="text-blue">Jackpots</span>
                </a>
              </a>
            </div>

            <div style="border-bottom: solid 1px #96a9de;" class="d-flex justify-content-left px-3 py-1 flatlink-div">
              <router-link to="print">
                <a class="flat-link" @click="closeMenu">
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" class="theme-dark mr-2"
                    style="color: #9ba6d3;">
                    <g opacity="0.39">
                      <path d="M4.5 6.75V1.5H13.5V6.75" stroke="white" stroke-linecap="round" stroke-linejoin="round">
                      </path>
                      <path
                        d="M4.5 13.5H3C2.60218 13.5 2.22064 13.342 1.93934 13.0607C1.65804 12.7794 1.5 12.3978 1.5 12V8.25C1.5 7.85218 1.65804 7.47064 1.93934 7.18934C2.22064 6.90804 2.60218 6.75 3 6.75H15C15.3978 6.75 15.7794 6.90804 16.0607 7.18934C16.342 7.47064 16.5 7.85218 16.5 8.25V12C16.5 12.3978 16.342 12.7794 16.0607 13.0607C15.7794 13.342 15.3978 13.5 15 13.5H13.5"
                        stroke="white" stroke-linecap="round" stroke-linejoin="round"></path>
                      <path d="M13.5 10.5H4.5V16.5H13.5V10.5Z" stroke="white" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                    </g>
                  </svg>
                  <span class="text-blue faso-menu-close"> Print Odds & Matches </span>
                </a>
              </router-link>
            </div>

            <!-- <div style="border-bottom: solid 1px #96a9de;" class="justify-content-left px-3 py-1 flatlink-div">
              <a @click="goTo('offers')">
                <a class="flat-link">
                  <img src="/img/menu/offers.png" class="side-icon-svg mr-2">
                  <span class="text-blue faso-menu-close">Offers And Promotions</span>
                </a>
              </a>
            </div> -->

            <div class="d-flex justify-content-left px-3 py-1 flatlink-div">
              <strong class="text-light">Player Account</strong>
            </div>

            <div style="border-bottom: solid 1px #96a9de;" class="d-flex justify-content-left px-3 py-1 flatlink-div">
              <a @click="goTo('bets')">
                <a class="flat-link">
                  <img src="/menu/bet_history.png" class="wallet-icon mr-2">
                  <span class="text-blue">Bet History</span>
                </a>
              </a>
            </div>

            <!-- <div style="border-bottom: solid 1px #96a9de;" class="d-flex justify-content-left px-3 py-1 flatlink-div">
              <a @click="goTo('profile')">
                <a class="flat-link">
                  <img src="/menu/my_profile.png" class="side-icon-svg mr-2">
                  <span class="text-blue">My Profile</span>
                </a>
              </a>
            </div> -->

            <div style="border-bottom: solid 1px #96a9de;" class="d-flex justify-content-left px-3 py-1 flatlink-div">
              <a @click="goTo('my-account')">
                <a class="flat-link">
                  <img src="/menu/my_account.png" class="wallet-icon mr-2">
                  <span class="text-blue">My Account</span>
                </a>
              </a>
            </div>

            <div class="d-flex justify-content-left px-3 py-1 flatlink-div">
              <strong class="text-light">Help</strong>
            </div>

            <div style="border-bottom: solid 1px #96a9de;" class="d-flex justify-content-left px-3 py-1 flatlink-div">
              <a class="flat-link" @click="goTo('responsible-gaming')">
                <img src="/img/menu/age-limit.png" class="side-icon-svg mr-2">
                <span class="text-blue">Responsible Gaming</span>
              </a>

            </div>

            <div style="border-bottom: solid 1px #96a9de;" class="d-flex justify-content-left px-3 py-1 flatlink-div">
              <a @click="goTo('faq')">
                <a class="flat-link">
                  <img src="/menu/faq.png" class="side-icon-svg mr-2">
                  <span class="text-blue">Frequently Asked Questions</span>
                </a>
              </a>
            </div>

            <div style="border-bottom: solid 1px #96a9de;" class="d-flex justify-content-left px-3 py-1 flatlink-div">
              <a @click="goTo('terms')">
                <a class="flat-link">
                  <img src="/menu/terms.png" class="side-icon-svg mr-2">
                  <span class="text-blue">Terms and Conditions</span>
                </a>
              </a>
            </div>

            <div style="border-bottom: solid 1px #96a9de;" class="d-flex justify-content-left px-3 py-1 flatlink-div">
              <a @click="logout" class="flat-link">
                <img src="/menu/logout.png" class="side-icon-svg mr-2">
                <span class="text-danger">Logout</span>
              </a>
            </div>
          </section>
          <Footer />

        </div>


      </div>
      <!--End A-Z Menu-->
      <span id="menu-init" class="hidden"></span>
      <span class="faso-menu-close hidden"></span>

      <router-view></router-view>

      <div class="depo-widget d-none" id="dpo-widg" v-show="current_page != 'deposit'">
        <span id="dpo-close" class="text-light" @click="closeWidget()"><i class="bi bi-x-lg"></i></span>
        <router-link to="/deposit" class="deposit-link" v-show="current_page != 'deposit'">
          <img src="/img/depo.webp" class="depo-widg">
        </router-link>
      </div>


    </div>

  </div>
</template>

<script>
//import mqtt from "mqtt";
import Footer from "./components/Footer.vue";
import axios from "@/services/api";
import store from './store/store';

export default {
  store,
  name: 'app',
  data: function () {
    return {
      current_menu: '',
      amount: 49,
      iconSize: 24,
      iconBigSize: 30,
      mqttClient: false,
      myProfile: this.getProfile(),
      loading: '',
      message: 'Please enter amount you want to deposit',
      aviatorGame: [
        {
          providerID: 7,
          gameID: "aviator",
          gameName: "Aviator",
          image: "/img/home/Aviator.jpg",
        },
      ],
      isWebView: false,
      referralCode: null,
      homePageGame: [
        // {
        //   providerID: 5,
        //   gameID: "FootballX",
        //   gameName: "FootballX",
        //   image: "/img/home/FootballX.png",
        //   crashGameUrl: "footballx",
        // },
        {
          providerID: 5,
          gameID: "JetX",
          gameName: "Jet X",
          image: "/img/home/JetX.jpg",
          crashGameUrl: "jetx",
        },
        // {
        //   providerID: 9,
        //   gameID: "reelwheel",
        //   gameName: "Spin to Win",
        //   image: "/img/home/Spin2Win.jpg",
        // },
        // {
        //   providerID: 3,
        //   gameID: "1",
        //   gameName: "Comet",
        //   image: "/img/home/CometCrash.jpg",
        //   crashGameUrl: "comet",
        // },
        // {
        //   providerID: 5,
        //   gameID: "JetX",
        //   gameName: "JetX",
        //   image: "/img/home/JetX.jpg",
        // },
      ],
    }
  },
  components: {
    Footer
  },
  mounted: function () {
    var vm = this;

    vm.myProfile = vm.getProfile();

    this.checkIfWebView();

    this.initMenuModal();
    this.getSports();
    setInterval(function () {

      vm.myProfile = vm.getProfile();

    }, 2000);
    this.EventBus.$on('deposit:popup', function (payload) {
      vm.amount = payload.amount;
      vm.message = payload.message;
      if (document.getElementById("deposit-init") != null) {
        document.getElementById("deposit-init").click();
      }

    });


    this.getTopHeaderHeight();

    this.EventBus.$on('init:mqtt', function () {

      console.log('Wants Inititalize MQTT');

      if (vm.mqttClient !== false) {

        vm.mqttClient.end();
        vm.mqttClient = false;
      }

      vm.myProfile = vm.getProfile();
      vm.initMqtt();

    });

    vm.initMqtt();

    var params = this.getURLParameters();

    var utm_source = params.utm_source ? params.utm_source : ''
    var utm_medium = params.utm_medium ? params.utm_medium : ''
    var utm_campaign = params.utm_campaign ? params.utm_campaign : ''
    var referral_code = params.p ? params.p : ''
    var referrer = document.referrer
    var btag = params.btag ? params.btag : ''
    console.log('GOT referrer ' + referrer)

    if (btag.length > 0) {
      this.setValue('btag', btag)
    }

    if (utm_source.length > 0) {

      this.setValue('utm_source', utm_source)
    }

    if (utm_medium.length > 0) {

      this.setValue('utm_medium', utm_medium)
    }

    if (referral_code.length > 0) {

      this.setValue('referral_code', referral_code)
    }

    if (utm_campaign.length > 0) {

      this.setValue('utm_campaign', utm_campaign)
    }
    this.setValue('referrer', referrer)

    if (this.$refs.topHeader) {
      window.addEventListener('resize', this.getTopHeaderHeight);
    }
  },

  unmounted: function () {
    window.removeEventListener('resize', this.getTopHeaderHeight);
  },

  computed: {
    showAppBanner: function () {
      if (this.$store.state.show_app_banner) {
        return '';
      } else {
        return 'd-none';
      }
    },
    bal: function () {

      return this.formatCurrency(this.$store.state.balance);

    },
    full_name: function () {

      if (this.profile === undefined) {

        return ""
      }

      if (this.profile.f1 === undefined || this.profile.f1.length === 0) {

        return ms;

      }

      var fn = this.profile.f1;
      var mn = this.profile.f2;
      var ln = this.profile.f3;
      var ms = this.getUIValue(this.profile.m);
      return fn + ' ' + mn + ' ' + ln + ' - ' + ms;

    },
    profile: function () {

      return this.myProfile;

    },
    current_page: function () {

      return this.$store.state.current_page;

    },
    sports: function () {
      var s = this.$store.state.sports;
      var t = [];

      this.jQuery.each(s, function (k, v) {

        if (v.sport_id > 1) {

          t.push(v);
        }

      })

      return t;
    },
    homePageFontColor: function () {

      if (this.current_page === 'home') {

        return "yellow-txt";
      }

      return "";

    },
    livePageFontColor: function () {

      if (this.current_page === 'live') {

        return "yellow-txt";
      }

      return "";

    },
    historyPageFontColor: function () {

      if (this.current_page === 'history') {

        return "yellow-txt";
      }

      return "";

    },
    betslip: function () {

      //var bSlip = this.getObject("betslip");
      //return bSlip;
      return this.$store.state.betslip
    },
    activeBets: function () {

      var p = this.getProfile();

      if (!p) {

        return 0;

      }

      return p.b

    },
    betslip_count: function () {

      if (!this.betslip.total || this.betslip.total == "") {

        return 0
      }

      return parseInt(this.betslip.total);

    },

  },
  methods: {
    checkIfWebView() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      const isWebView = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(userAgent) ||
        (/\bwv\b/.test(userAgent)) ||
        (/Android.*Version\/[\d.]+.*(?:Chrome\/[\d.]+ Mobile Safari\/[\d.]+|wv)/i.test(userAgent));

      this.isWebView = isWebView;

      if (isWebView) {
        console.log("User is using a WebView.");
      } else {
        console.log("User is using a standard browser.");
      }
    },
    closeMenu() {
      document.getElementById("menu-modal").style.display = "none";
    },

    getReferrer() {
      return document.referrer || "No referrer found";
    },

    canStick: function () {
      if (this.$store.state.current_page == 'casinogames' || this.$store.state.current_page == 'virtuals') {
        return '';
      } else {
        return 'header-stick';
      }
    },
    canFeatureStick: function () {
      if (this.$store.state.current_page == 'match' || this.$store.state.current_page == 'casinogames' || this.$store.state.current_page == 'casino' || this.$store.state.current_page == 'casino-live' || this.$store.state.current_page == 'virtuals') {
        return '';
      } else {
        return 'feature-stick';
      }
    },
    isActive: function (page) {

      return this.current_page === page ? 'active' : '';

    },


    setActive: function (menu) {

      this.current_menu = menu;

    },
    livescore: function () {


    },

    initMqtt: function () {

      /*
      var endpoint = process.env.VUE_APP_URL_MQTT_HOST;
      var vm = this;


      if (this.mqttClient !== false) {

        this.mqttClient.end();

      }

      // connection option

      const options = {
        clean: true, // retain session
        connectTimeout: 4000, // Timeout period
        // Authentication information
        clientId: this.getClientID(),
        username: process.env.VUE_APP_URL_MQTT_USER,
        password: process.env.VUE_APP_URL_MQTT_PASS,
      }

      console.log('at endpoint ' + endpoint)

      var client = mqtt.connect(endpoint, options);

      client.on('connect', function () {

        console.log('connected here')

        if (vm.myProfile) {

          var profileTopic = 'topic/profile/' + vm.myProfile.id;
          profileTopic = profileTopic.toLowerCase();

          client.subscribe(profileTopic, function (err) {

            if (!err) {

              console.log('subscribed to topic ' + profileTopic);

            }

          });
        }

      })

      client.on('message', function (topic, msg) {
        // message is Buffer
        var payload = JSON.parse(msg.toString());
        vm.uxUpdate(payload);
      })

      this.mqttClient = client;

       */

    },

    getMarket: function (sport_id) {

      this.$store.dispatch("getMarkets", sport_id);
    },
    setSport: function (sport) {

      this.dismiss();
      //console.log('setSport ' + JSON.stringify(sport));

      this.sport = sport;
      this.sport_name = sport.sport_name;
      this.getMarket(sport.sport_id);
      this.$store.dispatch("setSportID", sport.sport_id);
      this.goHome();
    },
    setSportMenu: function (sport_id, sport_name) {
      this.sport_name = sport_name;
      this.$store.dispatch("setSportID", sport_id);
      this.$store.dispatch("setCurrentPage", sport_name);
      console.log(this.$store.state.sport_id)
      this.$router.push({ name: 'sport', params: {} });
    },



    initMenuModal: function () {

      // Get the modal
      var modal = document.getElementById("menu-modal");

      // Get the button that opens the modal
      var btn = document.getElementById("menu-init");

      // Get the <span> element that closes the modal
      var span = document.getElementsByClassName("faso-menu-close")[0];

      // When the user clicks on <span> (x), close the modal
      span.onclick = function () {
        modal.style.display = "none";
        document.body.style.position = '';
      }

      // When the user clicks the button, open the modal
      btn.onclick = function () {
        modal.style.display = "block";
        //document.body.style.position = 'fixed';
      }

      document.addEventListener("click", e => {

        if (e.target == document.querySelector("menu-modal")) {

          modal.style.display = "none";
        }

      });

      // When the user clicks anywhere outside of the modal, close it
      window.onclick = function (event) {

        if (event.target === modal) {

          modal.style.display = "none";

        }
      }

    },

    initDeposit: function () {

      this.dismiss();
      this.$router.push({ name: 'deposit' });
    },
    dismiss: function () {

      console.log('DISMISS MENU');
      var modal = document.getElementById("menu-modal");
      modal.style.display = "none";

    },

    getKey: function (index) {

      return Math.random().toString(10).replace('0.', 'sport-' + index + '-');

    },
    getSports: function () {
      this.$store.dispatch("getSports");
    },

    setAmount: function (amount) {
      this.amount = parseFloat(amount).toFixed(2);
      this.deposit();
    },

    deposit: function () {

      this.reset();
      var p = this.getProfile();

      if (!p) {

        this.$toast.open({
          message: 'Please login to proceed',
          type: 'error',
          position: 'top'
        });

        this.$router.push({ name: 'login', params: {} });
        return;

      }

      if (this.amount < 1) {

        this.$toast.open({
          message: 'Enter at least Ksh. 10 or above',
          type: 'error',
          position: 'top'
        });
        return;

      }

      var vm = this;
      var path = process.env.VUE_APP_URL_DEPOSIT.replace("{profile_id}", p.d);

      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");

      var data = {
        amount: parseInt(this.amount),
        utm_source: utm_source,
        utm_medium: utm_medium,
        utm_campaign: utm_campaign,
        referrer: document.referrer,
      };

      vm.loading = 'loading';

      axios.post(path, JSON.stringify(data), {
        headers: {
          'api-key': vm.getAuth()
        },
      })
        .then(res => {

          vm.loading = '';
          var msg = res.data.message;
          vm.setSuccess("Deposit Initiated", msg);
          vm.message = 'Please enter amount you want to deposit';

        })
        .catch(err => {

          vm.loading = '';
          if (err.response) {

            if (parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 || parseInt(err.response.status) === 428) {

              this.$toast.open({
                message: 'Your session on this device has expired',
                type: 'error',
                position: 'top'
              });

              vm.logout();
              return;

            } else {

              this.$toast.open({
                message: err.response.data.message,
                type: 'error',
                position: 'top'
              });

              console.log(JSON.stringify(err.response.data.message));
            }

          } else if (err.request) {

            this.$toast.open({
              message: 'Check your network connection and try again',
              type: 'error',
              position: 'top'
            });

            console.log(JSON.stringify(err.request));

          } else {

            this.$toast.open({
              message: 'Check your network connection and try again',
              type: 'error',
              position: 'top'
            });
            console.log(JSON.stringify(err));

          }

        })
    },

    showDeposit: function () {

      document.getElementById("deposit-init").click();

    },
    searchVisible: function () {

      console.log('this.current_page ==> ' + this.current_page);

      var searchVisible = this.current_page !== 'search';
      console.log('this.current_page ==> ' + this.current_page + ' searchVisible ==> ' + searchVisible);

      return searchVisible;

    },
    getTopHeaderHeight: function () {
      const height = this.$refs.topHeader?.clientHeight;

      //set root css variable
      document.documentElement.style.setProperty('--top-header-height', (height ?? 0) + 'px');


    },
  },
  created() {
    this.$store.dispatch('fetchReferralCode');
  },
  beforeDestroy: function () {

    console.log("beforeDestroy")
    if (this.mqttClient !== false) {

      this.mqttClient.end();
      this.mqttClient = false;

    }

  },
  destroyed: function () {

    console.log("destroyed")
    if (this.mqttClient !== false) {

      this.mqttClient.end();
      this.mqttClient = false;

    }

  }
}
</script>

<style scoped>
.icon-container {
  background-color: #23313D;
  width: 50px;
  height: 50px;
  display: flex;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
}

.crash-icon-container {
  background-color: #23313D;
  width: 50px;
  height: 50px;
  display: flex;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
}

.header {
  padding: 0.5rem 15px;
}

.logo {
  flex-grow: 1;
  gap: 1rem
}

.top-header {
  position: sticky;
}
</style>